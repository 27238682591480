import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const PostPreviewWrapper = styled.div`
  &:not(:first-of-type) {
    margin-top: 64px;
  }

  a {
    position: relative;
    display: block;
    text-decoration: none;
    color: var(--color-text);
  }

  h3 {
    font-size: 22px;
    color: var(--color-gray-1000);
    line-height: 1.6;
    letter-spacing: 1px;
  }

  h4 {
    color: var(--color-gray-600);
    font-weight: var(--font-weight-medium);
    line-height: 1.6;
    letter-spacing: 1px;
  }

  p {
    margin-top: 16px;
    font-size: 16px;
  }
`

export interface PostPreviewProps {
  slug: string
  title: string
  subtitle: string
  excerpt: string
}

const PostPreview: React.FC<PostPreviewProps> = ({
  slug,
  title,
  excerpt,
  subtitle,
}) => {
  return (
    <PostPreviewWrapper>
      <article>
        <Link to={`/${slug}`}>
          <h3>{title}</h3>
          {subtitle ? <h4>{subtitle}</h4> : null}
          <p>{excerpt}</p>
          <div>Read More . . .</div>
        </Link>
      </article>
    </PostPreviewWrapper>
  )
}

export default PostPreview
