import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import HomepageLayout from '../HomePage'
import HeadingSectionTitle from '../../components/HeadingSectionTitle/HeadingSectionTitle'
import PostPreview from '../../components/PostPreview/PostPreview'
import MaxWidthWrapper from '../../components/MaxWidthWrapper/MaxWidthWrapper'
import MostPopularPosts from './MostPopularPosts/MostPopularPosts'

const AllPostsWrapper = styled.div`
  position: relative;
  z-index: 2;
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'newest'
    'categories'
    'popular';
  gap: 64px 96px;
  padding-top: 64px;
  @media ${p => p.theme.QUERIES.tabletsAndBigger} {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'newest popular'
      'newest popular';
    gap: 64px 96px;
    padding-top: 64px;
  }
`

const LatestPostsWrapper = styled.div`
  grid-area: 'newest';
`

// TODO working on separates layout for this view

export interface AllPostsProps {
  pageContext: any
  data: any
}

const AllPosts: React.FC<AllPostsProps> = ({ data }) => {
  const posts = data.allMdx.edges

  return (
    <HomepageLayout>
      <MaxWidthWrapper>
        <AllPostsWrapper>
          <LatestPostsWrapper>
            <HeadingSectionTitle as="h2">
              Ostatnio opublikowane
            </HeadingSectionTitle>
            {posts.map((post: any) => (
              <PostPreview
                key={post.node.frontmatter.slug}
                slug={post.node.frontmatter.slug}
                title={post.node.frontmatter.title}
                subtitle={post.node.frontmatter.subtitle}
                excerpt={post.node.frontmatter.excerpt}
              />
            ))}
          </LatestPostsWrapper>
          <MostPopularPosts />
        </AllPostsWrapper>
      </MaxWidthWrapper>
    </HomepageLayout>
  )
}

export const pageQuery = graphql`
  query AllPostsQuery($limit: Int!) {
    allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: $limit) {
      edges {
        node {
          id
          frontmatter {
            date
            excerpt
            slug
            title
            subtitle
          }
        }
      }
    }
  }
`

export default AllPosts
