import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import HeadingSectionTitle from '../../../components/HeadingSectionTitle/HeadingSectionTitle'

const MostPopularPostsWrapper = styled.div`
  grid-area: 'popular';
`

const PopularContentContentWrapper = styled.div`
  @media ${p => p.theme.QUERIES.tabletsAndBigger} {
    margin-left: -28px;
  }
`

const PopularContentList = styled.ul`
  margin-bottom: 32px;
`

const PopularContentListItem = styled.li`
  margin-bottom: 16px;
  display: flex;
  align-items: baseline;
  span {
    display: none;
    padding-right: 16px;
    @media ${p => p.theme.QUERIES.tabletsAndBigger} {
      display: block;
    }
    div {
      width: 10px;
      height: 10px;
      background-color: var(--color-secondary);
      border-radius: 50%;
    }
  }
  a {
    display: block;
    text-decoration: none;
    color: var(--color-gray-1000);
    font-weight: var(--font-weight-medium);
    line-height: 1.45;
  }
`

export interface MostPopularPostsProps {}

const MostPopularPosts: React.FC<MostPopularPostsProps> = () => {
  return (
    <MostPopularPostsWrapper>
      <HeadingSectionTitle as="h2">Popularne wpisy</HeadingSectionTitle>
      <PopularContentContentWrapper>
        <PopularContentList>
          <PopularContentListItem>
            <span>
              <div></div>
            </span>
            <Link to="/mental/post-1">Post 1</Link>
          </PopularContentListItem>
          <PopularContentListItem>
            <span>
              <div></div>
            </span>
            <Link to="/mental/post-2">Post 2</Link>
          </PopularContentListItem>
        </PopularContentList>
      </PopularContentContentWrapper>
    </MostPopularPostsWrapper>
  )
}

export default MostPopularPosts
